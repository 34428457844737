// import {IPropertyCard} from '@Components/PropertyCard/types';
// import {APP_THEME} from '@env';
export interface IPropertyCard {
  __typename?: string;
  id: string;
  idFinca: string;
  title: string;
  slug?: string;
  link: string;
  code: string;
  subtitle: string;
  description: string;
  commonExpenses: any | null;
  commonExpenses_currency: string | null;
  operation_type_id: number;
  price_variation: PriceVaritation;
  price: Price | null;
  hidePrice: boolean;
  currency: string;
  finalization: string;
  constructionState: ConstructionState;
  price_amount_usd: number;
  temporal_currency?: Currency | null;
  temporal_price?: number | null;
  active: boolean;
  isProject?: boolean;
  isEspecial?: boolean;
  facilities: any;
  images: Image[];
  features?: ProjectFeature[];
  backup_img?: string;
  backup_title?: string;
  img?: string;
  tour3d?: string;
  files: FileType[];
  guarantee?: string;
  youtube?: string;
  video?: string;
  image_count: number;
  latitude?: number;
  longitude?: number;
  pointType?: boolean;
  property_type: PropertyType;
  bedrooms: number;
  rooms: number;
  bathrooms: number;
  m2?: number;
  minM2?: number;
  hectares: number;
  guests: number;
  technicalSheet: TechnicalSheetType[];
  owner: Owner;
  seller: Seller;
  commercial_units?: CommercialUnit[];
  architect: string;
  percentage_sold: number | null;
  percentage_finished: number | null;
  ocupation: Date | null;
  builder: string;
  grouped_ids?: string;
  isExternal?: boolean;
  highlight?: number;
  occupancies?: OccupancyType[];
  seasons?: Season[];
  seaview?: boolean;
  estate?: Estate;
  neighborhood?: Neighborhood;
  address?: string;
  showAddress?: boolean;
  seaDistanceName?: string;
  locations?: {
    location_main?: {
      name: string;
      location_type: string;
    };
    country: any;
    neighbourhood: any;
    state: any;
    city: any;
    commune: any;
    locality: any;
    zone: any;
  };
  tag: {
    ad_id: string;
    tag_type: string;
    tag_label: string;
    property_id: string | number;
    project_id: string | number;
  };
  isMapFeatured?: boolean;
  project: {
    id: number;
    idFincaLegacy: number;
    id_form: any;
    title: string;
    description: string;
    minPrice?: Price;
  };
  commercialUnits?: CommercialUnit[];
}

interface IMainLocation {
  property: IPropertyCard;
  withState?: boolean;
  withCountry?: boolean;
}

interface ILocationFormated {
  locations: any;
  type: string;
  name: string;
  withState?: boolean;
  withCountry?: boolean;
}

export const getMainLocation = ({
  property,
  withState = false,
  withCountry = true,
}: IMainLocation) => {
  const locations = property.locations;

  if (!locations || process.env.APP_THEME !== 'fincaraiz') {
    const neighbourhood =
      property.neighborhood?.name || property.neighborhood || null;
    const estate = property.estate?.name || property.estate || null;
    return `${neighbourhood}, ${estate}`;
  }

  const location_main = locations.location_main;

  if (Object.keys(location_main || {})?.length > 0) {
    return getLocationFormated({
      locations,
      type: location_main.location_type,
      name: location_main.name,
      withState,
      withCountry,
    });
  }

  const neighbourhood =
    locations.neighbourhood[0]?.name ||
    locations.commune[0]?.name ||
    locations.locality[0]?.name ||
    locations.zone[0]?.name ||
    null;
  const hasNeighbourhood =
    locations.neighbourhood?.length > 0 ||
    locations.commune?.length > 0 ||
    locations.locality?.length > 0 ||
    locations.zone?.length > 0;
  const city = locations.city[0]?.name || null;
  const state = locations.state[0]?.name || null;
  const country = locations.country[0]?.name || null;

  const city_state = city ? `${city}, ${state}` : state;

  if (hasNeighbourhood) {
    return `${neighbourhood}, ${
      withState ? city_state : city || state || country
    }`;
  }

  if (locations.city?.length > 0) {
    return `${city}, ${state || country}`;
  }

  if (locations.state?.length > 0) {
    return `${withCountry ? `${state}, ${country}` : state}`;
  }
};

export const getLocationFormated = ({
  locations,
  type = '',
  name,
  withState = false,
  withCountry = true,
}: ILocationFormated) => {
  // state- Departamento
  // city - Ciudad
  // zone - Zona
  // locality - Localidad
  // commune - Comuna
  // neighbordhood - Barrio

  // Mostrar de la siguiente forma:
  // Zona- Comuna- Barrio- Localidad + Ciudad

  if (!locations) return null;

  const city_name = resolveCityName(locations);
  const state_name = resolveStateName(locations);

  const city_state_name = city_name
    ? `${city_name}, ${state_name}`
    : state_name;

  // [Zona- Comuna- Barrio], Ciudad, Departamento
  if (
    ['ZONE', 'COMMUNE', 'NEIGHBOURHOOD', 'LOCALITY'].includes(
      type.toUpperCase(),
    )
  ) {
    return `${name}, ${
      withState ? city_state_name : city_name || state_name || 'Colombia'
    }`;
  }
  // Por ciudad es: ciudad, Departamento
  if (['CITY'].includes(type.toUpperCase())) {
    return `${name}, ${state_name || 'Colombia'}`;
  }
  // Por departamento: Departamento, Colombia
  if (['STATE'].includes(type.toUpperCase())) {
    return `${withCountry ? `${name}, Colombia` : name}`;
  }
};

const resolveCityName = (locations: any) => {
  let city_name = null;
  if (Array.isArray(locations.city) && locations.city?.length > 0) {
    city_name = locations.city[0].name;
  } else if (typeof locations.city == 'string') {
    city_name = locations.city;
  }
  return city_name;
};

const resolveStateName = (locations: any) => {
  let state_name = null;
  if (Array.isArray(locations.state) && locations.state?.length > 0) {
    state_name = locations.state[0].name;
  } else if (Array.isArray(locations.estate) && locations.estate?.length > 0) {
    state_name = locations.estate[0].name;
  } else if (typeof locations.estate == 'string') {
    state_name = locations.estate;
  } else if (
    Object.keys(locations).includes('estate') &&
    typeof locations.estate.name == 'string'
  ) {
    state_name = locations.estate.name;
  }
  return state_name;
};
