import { useMemo, useState } from "react";
import { useFilters } from "shared-components/Components/Filters/Filters.hook";
import { getLocationFormated } from "src/utils/mainLocation";

export const useLocation = () => {
  const {filters, filtersTags} = useFilters();

  const locationLabel = useMemo(() => {
    if (filtersTags.locations) {
      return filtersTags.locations?.label;
    }
    if(filtersTags?.estate_id) {
      return filtersTags.estate_id.text + (filtersTags?.neighborhood_id ? (', ' + filtersTags.neighborhood_id.reduce((acc, curr)=>{acc+=', ' + curr.text; return acc}, '').replace(', ', '')) : '')
    }
    return '';
  }, [filtersTags.locations, filtersTags.estate_id, filtersTags.neighborhood_id]);

  const locationDescription = useMemo(
    () =>
      getLocationFormated({
        locations: filtersTags.locations,
        type: filtersTags.locations?.type,
        name: filtersTags.locations?.name,
      }),
    [filtersTags.locations],
  );

  const searchParam = useMemo(() => {
    if (filters.locations) {
      return {
        locations: [
          {
            id: filtersTags.locations?.id,
            type: filtersTags.locations?.type,
          },
        ],
      };
    }
  }, [filters.locations]);

  const [mapCenter, setMapCenter] = useState(null);

  return {
    searchParam,
    locationLabel,
    locationDescription,
    mapCenter,
    setMapCenter,
  };
};
