import "./styles.less";

import React, { useContext } from "react";

import { Radio, Select } from "antd";
import { useOperationType } from "./OperationType.hook";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { IC_Collapse } from "shared-components/Components/Collapse/web";
import ButtonGroup from "../ButtonGroup/web";

const { Option } = Select;

const optsSeason = [
	{
		value: 0,
		title: "Buscar por Calendario"
	},
	{
		value: 1,
		title: "Buscar por Temporada"
	}
]

export const OperationType = ({ collapsable = false, home = false, ...props }) => {
	const { country_code } = useContext(ConfigStateContext);
	const isCeee = country_code === "ce3";
	const {
		show,
		error,
		loading,
		inputType,
		data: { options = [], value, onChange, customKey },
	} = useOperationType(props);
	const { showSeasonInput, handleChangeSeason } = props;
	let filter = null;

	if (!show || error) return null;

	const handleChange = val => {
		if (val) {
			const res = options.find(o => o[customKey] === val);
			onChange(res);
		}
	};

	if (inputType === 'select') {
		filter = (
			<Select
				disabled={loading}
				defaultValue={loading ? "Operación" : isCeee ? Number(showSeasonInput) : value}
				onChange={(e) => isCeee ? handleChangeSeason(!showSeasonInput) : handleChange(e)}
			>
				{isCeee
				? optsSeason.map((option, index) => (
					<Option key={`option-season-${index}`} value={option.value}>
						{option.title}
					</Option>
				))
				: options.map(o => (
					<Option key={`key_select_${o[customKey]}_operationType`} value={o[customKey]}>
						{o.name}
					</Option>
				))}
			</Select>
		)
	} else if (inputType === 'radioselect') {
		filter = (
			<Radio.Group onChange={e => handleChange(e.target.value)} value={value} disabled={loading}>
				{options.map(o => (
					<Radio value={o[customKey]} key={`key_${o[customKey]}_operationType`}>{o.name}</Radio>
				))}
			</Radio.Group>
		)
	} else if (inputType === 'buttonGroup') {
		filter = (
			<>
				<ButtonGroup
					options={options.map(e=>{return {text: e.name, value: e.operation_type_id}})}
					selected={value}
					allowMuliple={false}
					onChange={handleChange}
				/>

				<hr style={{width:"100%", border:'none', borderTop:'1px solid rgba(0,0,0,.06)', margin:'24px 0'}} />
			</>
		);
	} else {
		filter = (
			isCeee ?  (
				<Radio.Group
					defaultValue={Number(showSeasonInput)}
					buttonStyle="solid"
					optionType="button"
					onChange={() => handleChangeSeason(!showSeasonInput)}
				>
					{optsSeason.map((option, index) => (
						<Radio.Button key={`button-season-${index}`} value={option.value}>{option.title}</Radio.Button>
					))}
				</Radio.Group>
			) : (
				<Radio.Group
					disabled={loading}
					onChange={e => handleChange(e.target.value)}
					optionType="button"
					buttonStyle={"solid"}
					value={value}>
					{options.map(o => (
						<Radio.Button
							key={`key_buttons_${o[customKey]}_operationType`}
							value={o[customKey]}>
							{o.name}
						</Radio.Button>
					))}
				</Radio.Group>
			)
		)
	}

	return (
		<div className="filter operation-type-filter">
			{collapsable 
				? <IC_Collapse label="Tipo de Operación" isActive={value}>
					{filter}
				</IC_Collapse>
				: <>
					{inputType === "buttonGroup" && <h3 className="filter-title">Tipo de Operación</h3>}

					{filter}
				</>
			}
		</div>
	);
};
