import { useSearchResultsFilters } from "shared-components/ViewFragments/SearchResults/Sider/Filters/SearchResultsFilters.hook";
import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { useFilters } from "../Filters.hook";

const FRAGMENT_OPERATIONTYPES_OPTIONS = new FragmentDefiner(
	"Filter",
	`
      id
      name
      options
`
);
export const FRAGMENT_OPERATIONTYPES = new FragmentDefiner(
	"Filters",
	`
    operationType {
      ${FRAGMENT_OPERATIONTYPES_OPTIONS.query()}
    }
`
);

const useOperationType = props => {
	const { filters } = useFilters();
    const { changeFiltersAndUpdateURL: changeFilters } = useSearchResultsFilters();
	const {
		labeled = false,
		selectedValue = filters?.operation_type_id,
		inputType = "select",
		filterChanged = changeFilters,
	} = props;
	const { loading, data, error } = useReadFragment(
		FRAGMENT_OPERATIONTYPES_OPTIONS,
		"operationType"
	);

	const onChange = newOperation => {
		let values : any = {
			operation_type_id: newOperation
				? { value: newOperation.operation_type_id, text: newOperation.name }
				: null,
			minPrice: undefined,
			maxPrice: undefined,
		}

		if (newOperation.operation_type_id === 1) values = {...values, currencyID: {text: null, value: 1} }

		filterChanged(values);
	};

	return {
		loading: loading,
		error: error,
		data: {
			options: data?.options,
			value: selectedValue,
			onChange,
			customKey: "operation_type_id",
		},
		show: true,
		labeled,
		label: data?.name,
		inputType,
	};
};

export { useOperationType };
