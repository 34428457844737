import "./styles.less";

import { AutoComplete, Button, Col, Input, Row, Space, Typography } from "antd";
import { KeywordLocationProps, useKeywordLocation } from "./KeywordLocation.hook";
import React, { Suspense, useContext, useEffect, useMemo, useRef, useState } from "react";

import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { capitalize, encodeHashUrl } from "../../../Utils/Functions";
import { useRouter } from "next/router";
import { useTheme } from "../../../Styles/ThemeHook";
import { LocationIcon } from "shared-components/Components/CustomIcons/LocationIcon/web";
import { getTraduction } from "src/functions/functions";
import { useFilters } from "../Filters.hook";
import { useLocation } from "src/hooks/useLocation";
import { SearchOutlined } from "@ant-design/icons";
import { useHomeFilters } from "shared-components/ViewFragments/Home/HomeFilters/HomeFilters.hook";

export const KeywordLocation = ({
	className,
	searchByProject = false,
	searchByReference = true,
	isSearchForHome = false,
	changeKeyword = null,
	isMobile = false
}: KeywordLocationProps) => {
	const [locations, setLocations] = useState([]);

	const {
		show,
		keyword,
		onSearch,
		onSelect,
		searchLocationQuery,
		searchResults,
		refResults,
		searchLoading,
		projectsResults,
		location,
	} = useKeywordLocation({
		className,
		searchByProject,
		searchByReference,
		locations,
	});
	const ref = useRef(null);
	const { country_code } = useContext(ConfigStateContext);
	const router = useRouter();
	const { theme } = useTheme();
	const { locationLabel } = useLocation();
	const { filters, search, filtersTags } = useHomeFilters();
	const [isOpen, setOpen] = useState(false);
	const [isSearchTriggered, setIsSearchTriggered] = useState(false);

	useEffect(() => {
		if (location) ref.current.blur();
	}, [location]);
	
	useEffect(() => {
		if (isSearchForHome && searchResults) {
			if (isSearchTriggered && !searchLoading) {
				if (searchResults?.searchLocation.length > 0) {
					setLocations(searchResults?.searchLocation)
					onSelect(keyword, searchResults?.searchLocation[0]);
				} else if (keyword && keyword.length > 0) {
					onSelect(keyword, { label: keyword, __typename: "keyword" });
				}
				
				setIsSearchTriggered(false);
			}
		}
	}, [searchResults, isSearchTriggered, searchLoading]);

	useEffect(() => {
		if (search.response.data && !search.response.loading) {
			setRecientLocations(filtersTags.neighborhood_id, filtersTags.estate_id);
			router.push(
				{
					pathname: "/searchPage",
					query: { hashed: encodeHashUrl({ filters: filtersTags }) },
				},
				search.response.data.searchUrl.url
			);
		}
	}, [search.response]);

	const doSearchWithoutKeyword = () => search.send({ variables: { params: filters } });

	const handleDoSearch = () => {
		setIsSearchTriggered(true);
		if (!!keyword.length) searchLocationQuery({ variables: { strSearch: keyword } });
		else doSearchWithoutKeyword();
	}

	if (!show) return null;

	const renderOption = ({ value, label, data, group = null, icon }) => {
		return {
			key: data.id,
			data: data,
			value: value,
			label: (
				<Row>
					{country_code != "BR" && (
						<Col style={{ marginRight: theme.spacing.smSpacing }}>{icon}</Col>
					)}
					<Typography.Text style={{ flex: "1", whiteSpace: "break-spaces" }}>
						{label}
					</Typography.Text>
					{(group && country_code !== "ce3") && (
						<Col style={{ marginLeft: theme.spacing.smSpacing }}>
							<Typography.Text type="secondary">{group}</Typography.Text>
						</Col>
					)}
				</Row>
			),
		};
	};

	const renderTitle = title => {
		return (
			<Row>
				<Typography.Text strong>{title}</Typography.Text>
			</Row>
		);
	};

	let options = [];
	let optionsData = [];
	let optionsTitle = "";

	// location options && recient searches
	if (searchResults?.searchLocation.length > 0 && keyword && keyword.length > 0) {
		optionsData = searchResults.searchLocation;
		optionsTitle = "Ubicación";
	} else if (keyword && keyword.length == 0 && isOpen) {
		optionsData = getRecientLocations();
		optionsTitle = "Recientes";
	}

	// projects options
	if (
		searchByProject &&
		projectsResults?.searchListing &&
		projectsResults?.searchListing.length > 0 &&
		keyword.length > 0
	) {
		options = [
			...options,
			{
				label: renderTitle("Proyectos"),
				options: projectsResults.searchListing.data.map((p, i) => {
					return renderOption({
						label: <Typography.Text>{p.title}</Typography.Text>,
						value: p.title,
						data: p,
						icon: <Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/HomeOutlined")))))}
						</Suspense>,
						group: "Proyecto",
					});
				}),
			},
		];
	}

	// reference options
	if (searchByReference && refResults?.searchByRef && keyword.length >= 5) {
		options = [
			...options,
			{
				label: renderTitle("Código de Referencia"),
				options: [
					renderOption({
						label: <Typography.Text>{refResults.searchByRef.title}</Typography.Text>,
						value: refResults.searchByRef.id,
						data: refResults.searchByRef,
						icon: <Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/KeyOutlined")))))}
						</Suspense>,
					}),
				],
			},
		];
	}

	// keyword option
	if (!searchLoading && keyword && keyword.length > 0) {
		options = [
			...options,
			{
				label: renderTitle("Palabra Clave"),
				options: [
					renderOption({
						label: (
							<>
								{"Buscar inmuebles relacionados a" + " "}
								<Typography.Text strong>{keyword}</Typography.Text>
							</>
						),
						value: " " + keyword,
						data: { label: keyword, __typename: "keyword" },
						icon: <Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/SearchOutlined")))))}
						</Suspense>,
					}),
				],
			},
		];
	}

	if (optionsData.length > 0) {
		options = [
			{
				label: renderTitle(optionsTitle),
				options: optionsData.map(o => {
					const label =
						o.__typename == "Neighborhood" ? o.name + ", " + o.estate.name : o.name;
					const group = o.__typename == "Neighborhood" ? capitalize(getTraduction("barrio", country_code)) : "Departamento";
					const icon = (
						<Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/EnvironmentOutlined")))))}
						</Suspense>
					);
					return renderOption({
						value: label,
						label,
						data: { ...o, label: label },
						icon,
						group,
					});
				}),
			},
		];
	}

	return (
		<div className="keyword-location__container">
			<AutoComplete
				className={"filter keyword-location " + className}
				ref={ref}
				backfill
				allowClear
				clearIcon={
					<Suspense fallback={<div>...</div>}>
						{React.createElement(React.lazy((async () => (await import("@ant-design/icons/CloseOutlined")))))}
 					</Suspense>}
				value={keyword}
				options={options}
				notFoundContent={
					<Space style={{ padding: `${theme.spacing.xsSpacing}px 0` }}>
						{keyword != "" && <Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/LoadingOutlined")))))}
						</Suspense>}
						<Typography.Text type={"secondary"}>
							{keyword != "" ? "Cargando" : "Escribe una ubicación o palabra clave"}
						</Typography.Text>
					</Space>
				}
				dropdownClassName={
					"dropdown-keyword-location" + (className ? ` ${className}-dropdown` : "")
				}
				onSearch={onSearch}
				onSelect={(a, b) => {
					if (b.data.__typename == "Property") {
						let data = {
							...b.data,
							pathname: "/propSingle",
							link: b.data.link,
							avoid: false,
						};

						if (b.data.project.length > 0) {
							data.pathname = "/projectSingle";
							data.void = b.data.project[0].isEspecial;
							data.link = b.data.project[0].link;
							data.id = b.data.project[0].id;
						}

						if (b.data.isExternal) {
							window.open(
								data.link,
								"_blank",
								"location=yes,height=670,width=620,scrollbars=yes,status=yes"
							);
						} else if (data.avoid) {
							window.location = data.link;
						} else {
							router.push(
								{
									pathname: data.pathname,
									query: { hashed: encodeHashUrl(data) },
								},
								"/" + data.link
							);
						}
					} else if (b.data.__typename == "Project") {
						let data = {
							pathname: "/projectSingle",
							void: b.data.isEspecial,
							link: b.data.link,
							id: b.data.id,
						};

						router.push(
							{
								pathname: data.pathname,
								query: { hashed: encodeHashUrl(data) },
							},
							"/" + data.link
						);
					} else {
						onSelect(a, b.data);
					}
				}}
				onBlur={e => {
					if (location) onSearch(location.label);
				}}
				onDropdownVisibleChange={e => setOpen(e)}
				placeholder={country_code === "ce3" ? <><LocationIcon /> Ubicación</> : "Buscá por ubicación o palabra clave"}
				>
				<Input
					onPressEnter={handleDoSearch}
				/>
			</AutoComplete>

			<Button
				className="search-button superPrimary"
				type="primary"
				loading={searchLoading && isSearchTriggered || search.response.loading}
				onClick={handleDoSearch}
				icon={<SearchOutlined />}
			/>
		</div>
	);
};

export const LOCAL_STORAGE_KEY = "RecientLocations";
const getRecientLocations = () => {
	const items = localStorage?.getItem(LOCAL_STORAGE_KEY);
	let res = [];
	if (items) res = JSON.parse(items);
	return res;
};

export const setRecientLocations = (neighborhood, estate) => {
	if (estate) {
		let location = {};
		location["id"] = estate["value"];
		location["name"] = estate["text"];
		location["__typename"] = "Estate";
		if (neighborhood && neighborhood.length > 0) {
			location["estate"] = { ...location };
			location["id"] = neighborhood[0]["value"];
			location["name"] = neighborhood[0]["text"];
			location["__typename"] = "Neighborhood";
		}
		let res = getRecientLocations();
		res = res.filter(l => l.id != location["id"] || l["__typename"] != location["__typename"]);
		res = [{ ...location }, ...res];
		if (res.length > 5) res.pop();
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(res));
	}
};

