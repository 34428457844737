import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { getOnlyValues, sanitizeMapBound, useFilters } from "../../../../Components/Filters/Filters.hook";
import { encodeHashUrl } from "../../../../Utils/Functions";
import { useRouter } from "next/router";
import { QUERY_AVAILABLE_FILTERS } from "shared-components/GQL";

const QUERY_SEARCH_URL = gql`
	query searchUrl($params: SearchParamsInput!) {
		searchUrl(params: $params) {
			url
		}
	}
`;

const useSearchResultsFilters = (forceUrlUpdate = true) => {
	const { filters, changeFilters, filtersTags } = useFilters();
	const router = useRouter();

	useEffect(() => {
		const handlePopstate = () => {
			window.location.reload();
		};
		window.addEventListener("popstate", handlePopstate);

		return () => {
			window.removeEventListener("popstate", handlePopstate);
		};
	}, []);

	const [fetchSearchUrl] = useLazyQuery(QUERY_SEARCH_URL, {
		onCompleted: dataURL => {
			if (typeof window !== "undefined" && window.history) {
				router.push(
				{
					pathname: "/searchPage",
					query: { hashed: encodeHashUrl({ filters: filtersTags }) },
				},
				dataURL.searchUrl.url
				);
			}
		},
	});
	  
	const changeFiltersAndUpdateURL = (new_filters) => {
		changeFilters(new_filters).then((currentFilters) => {
			let formatCurrentFilters = sanitizeMapBound(getOnlyValues(currentFilters));

			const appliedFilters = { ...filters };
			appliedFilters.page = 1;

			fetchSearchUrl({ variables: { params: {...appliedFilters, ...formatCurrentFilters} } });
		});
	};

	const updateURL = () => {
		let formatCurrentFilters = sanitizeMapBound(getOnlyValues(filtersTags));

		fetchSearchUrl({ variables: { params: {...filters, ...formatCurrentFilters} } });
	}
	  
	const { data, loading, error } = useQuery(QUERY_AVAILABLE_FILTERS);

	return {
		data,
		loading,
		error,
		changeFiltersAndUpdateURL,
		updateURL
	};
};

export { useSearchResultsFilters };
