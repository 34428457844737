import "./styles.less";

import { Checkbox, Col, Row, Select, Skeleton, Space } from "antd";

import React, { useEffect, useState } from "react";
import { usePropertyType } from "./PropertyType.hook";
import { useTheme } from "../../../Styles/ThemeHook";
import { IC_Collapse } from "shared-components/Components/Collapse/web";
import { ArrowIcon } from "shared-components/Components/CustomIcons/ArrowIcon/web";

const { Option } = Select;

export const PropertyType = ({ collapsable = false, ...props }) => {
	const { theme } = useTheme();
	const {
		show,
		error,
		loading,
		inputType,
		label,
		labeled,
		data: { options, onChange, value = [], customKey },
	} = usePropertyType(props);
	const [showMore, setShowMore] = useState(false);
	let filter = null;
	const {
		showUpTo = null
	  } = props;

	if (!show) return null;
	if (error) return null;

	useEffect(()=>{
		if(showUpTo && showUpTo < options?.length && value?.length) {
			const result = value.some(val => {
				const index = options.findIndex(option => option.property_type_id === val);

				return index >= showUpTo;
			});
		  
			if(result) {
				setShowMore(true)
			}
		}
	}, [])

	const handleChange = checked => {
		const res = options.filter(o => checked.includes(o[customKey]));
		onChange(res);
	};

	const handleBoxChange = pressedOption => {
		let temp = [];
		if(value.includes(pressedOption.property_type_id)) {
		  temp = value.filter(o => o !== pressedOption.property_type_id);
		} else {
		  temp = [...value, pressedOption.property_type_id];
		}
	
		const res = options.filter(o => temp.includes(o[customKey]));
		setTimeout(() => {
		  onChange(res);
		}, 10);
	}

	if (inputType == "checkboxselect") {
		filter = loading ? (
			<Space style={{ width: "100%" }} size={0} direction={"vertical"}>
				<Skeleton title={false} active paragraph={{ rows: 1, width: "100%" }} />
				<Skeleton title={false} active paragraph={{ rows: 1, width: "100%" }} />
				<Skeleton title={false} active paragraph={{ rows: 1, width: "100%" }} />
			</Space>
		) : (
			<Checkbox.Group value={value} onChange={handleChange}>
				<Row gutter={[0, theme.spacing.lgSpacing]}>
					{options?.map(o => (
						<Col span={24} key={`key_${o[customKey]}_propertyType`}>
							<Checkbox value={o[customKey]}>{o.name}</Checkbox>
						</Col>
					))}
				</Row>
			</Checkbox.Group>
		);
	} else if (inputType == "boxes") {
		filter = (
			<>
				<div className="boxes-container">
					{options.map((o,i) =>
						{
							return (!showUpTo || showMore || (i < showUpTo && !showMore)) && (
								<div
									key={o.property_type_id}
									className={`boxes-container__item ${value?.includes(o.property_type_id) ? "boxes-container__item--active " : ""}`}
									onClick={()=>{handleBoxChange(o)}}
								>
									<span>{o.plural}</span>
								</div>
							)
						}
					)}
				</div>

				{showUpTo && options.length > showUpTo && 
					<div className="show-more__container">
						<button
							onClick={()=> setShowMore(!showMore)}
							className="show-more__button"
						>
							<span>{showMore ? 'Ver menos' : 'Ver más'}</span>
							{showMore ? <ArrowIcon type="top" /> : <ArrowIcon type="bottom" />}
						</button>
					</div>
				}

				<hr style={{width:"100%", border:'none', borderTop:'1px solid rgba(0,0,0,.06)', margin:'24px 0'}} />
			</>
		);
	} else {
		filter = loading ? (
			<Skeleton.Button active className="property-type-skeleton" />
		) : (
			<Select
				disabled={loading}
				value={loading ? "Tipo de Propiedad" : value}
				mode={"multiple"}
				showArrow
				optionFilterProp="children"
				onChange={handleChange}
				style={{ width: "100%" }}
				placeholder={"Seleccione un Tipo de Propiedad"}>
				{options?.map(o => (
					<Option key={`name_${o[customKey]}`} value={o[customKey]}>
						{o.name}
					</Option>
				))}
			</Select>
		);
	}

	return (
		<>
			<div className="filter property-type-filter">
				{collapsable 
					? <IC_Collapse label={label} isActive={value.length >= 1} startOpen={value.length <= 0}>
						{filter}
					</IC_Collapse>
					:  <>
						{inputType === "boxes" && <h3 className="filter-title">Tipo de Propiedad</h3>}

						{filter}
					</>
				}
			</div>
			<style jsx global>{`
				.home-filters .property-type-filter .ant-select .ant-select-selector {
					text-overflow: ${value.length > 0 ? "ellipsis" : "unset"};
				}
			`}</style>
		</>
	);
};
